/* eslint-disable react-hooks/exhaustive-deps */
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';

import { ArrowClockwise, CheckLg, X, PencilSquare, Play, PlusSquare, SlashCircle, Trash3 } from 'react-bootstrap-icons';

import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../helpers/AuthContext";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import Spinner from 'react-bootstrap/Spinner';

import api from '../../api';

function AlertList() {
    const { userId, checkLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const [activeAlert, setActiveAlert, runAlert] = useOutletContext();
    const [alertId, setAlertId] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [alerts, setAlerts] = useState([]);

    const [showDelete, setShowDelete] = useState(false);
    const [toDelete, setToDelete] = useState("");

    let stream = null;

    const { t } = useTranslation();

    function handleNewButton(id) {
        navigate("/alerts/edit/")
    }

    function loadListData() {
        setIsLoading(true);
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/alerts";

        return api.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setAlerts(res.data.alerts);
            }
            setIsLoading(false);
        });
    }

    function handleEditButton(id) {
        setAlertId(id)
        navigate("/alerts/edit/" + id)
    }

    function handleDeleteButton(id) {
        setToDelete(id);
        setShowDelete(true);
    }
    function handleDeleteOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/alert";
        api.delete(ENDPOINT, {
            data: { id: toDelete },
            withCredentials: true,
        }).then((res) => {
            setToDelete("");
            setShowDelete(false)
            loadListData()
            if (activeAlert === toDelete) {
                setActiveAlert("");
            }
        });
    }
    function handleDeleteCancel() {
        setToDelete("");
        setShowDelete(false);
    }

    function subscribeToSocket(uid) {
        if (stream !== null) {
            stream.removeEventListener("alert-status", loadListData);
            stream.close();
            stream = null;
        }

        let roomID = userId;
        if (!userId) {
            roomID = uid;
        }
        const SOCKET_ENDPOINT = process.env.REACT_APP_API_URL.replace("http", "ws") + "stream/" + roomID;

        stream = new WebSocket(SOCKET_ENDPOINT);
        stream.addEventListener("alert-status", loadListData);
        window.addEventListener("unload", function () {
            if (stream.readyState === WebSocket.OPEN) stream.close();
        });
    }

    function setRunning(id) {
        alerts.forEach((item) => {
            if (item._id === id) {
                item.status = "running";
                return;
            }
        })
        setAlerts([...alerts]);
    }

    useEffect(() => {
        checkLoggedIn().then((res_id) => {
            if (!res_id) {
                navigate("/login");
            }
            if (res_id) {
                const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                api.get(INFO_ENDPOINT, {
                    params: { id: res_id },
                    withCredentials: true,
                }).then((res) => {
                    if (res && res.data) {
                        loadListData();
                        subscribeToSocket(res_id);
                    } else {
                        navigate("/login");
                    }
                })
                    .catch((e) => {
                        console.error(e);
                    })
            } else {
                navigate("/");
            }
        });

        return () => {
            if (stream !== null && stream.readyState === WebSocket.OPEN) {
                stream.close();
                stream = null;
            }
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <>
            <div className='row my-2'>
                <div className="col d-flex justify-content-start">
                    <h4 className='madevo-text-secondary'>{t("Alerts")}</h4>
                </div>
                <div className="col d-flex justify-content-center">
                    {isLoading &&
                        <Spinner animation="border" role="status" size='sm' variant='secondary'>
                            <span className="visually-hidden">{t("Loading")}...</span>
                        </Spinner>
                    }
                </div>
                <div className="col d-flex justify-content-end py-1">
                    {!isLoading &&
                        <Button variant='outline-secondary' className='btn-sm mx-2' onClick={loadListData}>
                            <ArrowClockwise />
                        </Button>
                    }
                    <Button variant='outline-secondary'
                        className='btn-sm ml-2 madevo-btn-green new-btn'
                        onClick={handleNewButton}
                    >
                        <PlusSquare className='new-btn-plus' /> {t("New Alert")}
                    </Button>
                </div>
            </div>
            {!isLoading &&
                alerts.map((item) => {
                    
                    let has_failed = false;
                    let history_length = item.check_history.length;
                    if (item.check_history.length > 0) {
                        let checks = item.check_history[0];
                        if (checks != null) {
                            for (var i = 0; i < checks.length; i++) {
                                if (checks[i]['status'] === 'failed') {
                                    has_failed = true;
                                    break;
                                }
                            }
                        }
                    }

                    return (
                        <ListGroup className='my-2' key={item._id}>
                            <ListGroup.Item key={item._id}>
                                <div className='row'>
                                    <div className="col d-flex flex-column justify-content-start">
                                        <h5 className="mb-1">
                                            {item.title}
                                        </h5>
                                        <small className="text-body-secondary">
                                            {history_length === 0 &&
                                                <Badge bg="secondary" className="mx-1">
                                                    <SlashCircle /> {t("No Runs")}
                                                </Badge>
                                            }
                                            {history_length > 0 && has_failed &&
                                                <Badge bg="danger" className="mx-1">
                                                    <X /> {t("failed")}
                                                </Badge>
                                            }
                                            {history_length > 0 && !has_failed &&
                                                <Badge bg="success" className="mx-1">
                                                    <CheckLg /> {t("passed")}
                                                </Badge>
                                            }
                                        </small>
                                    </div>
                                    <div className="col d-flex justify-content-center mt-3">
                                        <small>
                                            {!item.is_active &&
                                                <strong>
                                                    {t("Inactive")}
                                                </strong>
                                            }
                                            {item.is_active &&
                                                <strong>
                                                    {t("Active")}
                                                </strong>
                                            }
                                        </small>
                                    </div>
                                    <div className="col d-flex justify-content-end mt-3">
                                        {item.status === 'running' &&
                                            <Spinner animation="border" role="status" size='sm' variant='secondary'>
                                                <span className="visually-hidden">{t("Running")}...</span>
                                            </Spinner>
                                        }
                                        {item.status !== 'running' &&
                                            <Play role='button' className='list-action' onClick={() => { runAlert(item._id); setRunning(item._id) }} title={t("Run")} />
                                        }
                                        <PencilSquare role='button' className='list-action' onClick={() => handleEditButton(item._id)} title={t('Edit')} />
                                        <Trash3 role='button' className='list-action red-action' onClick={() => handleDeleteButton(item._id)} title={t('Delete')} />
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    )
                }
                )
            }
            {showDelete &&
                <>
                    <Modal
                        show={showDelete}
                        onHide={() => setShowDelete(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.delete.alert")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleDeleteCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleDeleteOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }

        </>
    );
}

export default AlertList;

