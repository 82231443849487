import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import AuthContext from '../../helpers/AuthContext';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function RoleEdit(props) {
    function handleClose() {
        props.handleShow(false);
        setTimeout(() => {
            props.setRoleId("");
        }, 500);
    }

    const [saving, setSaving] = useState(false)
    const [confirming, setConfirming] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation();

    function handleSave() {
        setSaving(true);
        setConfirming(true);
    }

    function handleCancel() {
        setConfirming(false);
        setSaving(false);
    }

    function handleOk() {
        setConfirming(false);

        saveRole()
            .then((res) => {
                setSaving(false);
                handleClose();
                props.reLoad();
            })
    }

    function handleRename(e) {
        currentRole.name = e.target.value;
    }

    function handleChangeDefault(e) {
        currentRole.is_default = e.target.checked;
    }

    function handleAccessSwitch(e) {
        const name = e.target.name;
        const val = e.target.checked;
        currentRole.access[name] = val;
    }

    function isDisabled(name) {
        return ['admin', 'dummy', 'manager', 'agent'].includes(name);
    }

    function newRole() {
        return {
            name: "New Role",
            is_default: false,
            access: {
			"manage_roles":       false,
			"manage_all_users":   false,
			"manage_child_users": false,

			"manage_all_companies": false,
			"manage_self_company":  false,
            }
        }
    }

    const [currentRole, setCurrentRole] = useState(null);

    const { userId, checkLoggedIn } = useContext(AuthContext);

    function saveRole() {
        if (isNew) {
            const ENDPOINT = process.env.REACT_APP_API_URL + "admin/role";
            return axios.post(ENDPOINT, currentRole, {
                withCredentials: true,
            })
                .then((res) => {
                    setIsNew(false);
                });
        }
        const ENDPOINT = process.env.REACT_APP_API_URL + "admin/role";
        return axios.put(ENDPOINT, currentRole, {
            withCredentials: true,
        });
    }

    useEffect(() => {
        setIsLoading(true);
        checkLoggedIn().then((res) => {
            if (res) {
                if (props.role_id !== undefined && props.role_id.length > 0) {
                    const ENDPOINT = process.env.REACT_APP_API_URL + "admin/role";
                    axios.get(ENDPOINT, {
                        params: { id: props.role_id },
                        withCredentials: true,
                    })
                        .then((res) => {
                            let role = res.data.role;
                            setCurrentRole(role);
                        })
                        .catch((err) => {
                            if (err.response) {
                                setCurrentRole(null)
                            }
                        })
                } else {
                    setCurrentRole(newRole())
                    setIsNew(true)
                }
                setIsLoading(false);
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <>
            <Modal
                show={confirming}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Are you sure?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isNew &&
                        <p>{t("confirm.new.role")}</p>
                    }
                    {!isNew &&
                        <p>{t("confirm.modify.role")}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="success" onClick={handleOk}>
                        {t("Yes")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    {!isLoading && isNew &&
                        <Modal.Title>{t("New Role")}</Modal.Title>
                    }
                    {!isLoading && !isNew &&
                        <Modal.Title>{t("Edit Role")}</Modal.Title>
                    }
                    {isLoading &&
                        <Modal.Title>{t("Loading")}...</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    {isLoading &&
                        <div className="text-center">
                            <Spinner animation="border" role="status" size='sm' variant='secondary'>
                                <span className="visually-hidden">{t("Loading")}...</span>
                            </Spinner>
                        </div>
                    }
                    <Row>
                        <Col>
                            {!isLoading && currentRole !== null &&
                                <Form
                                    className="pb-2 mb-2"
                                >
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridName">
                                            <Form.Label>{t("Role Name")}</Form.Label>
                                            <Form.Control name="name" disabled={isDisabled(currentRole.name)} type="name" defaultValue={currentRole.name} onChange={handleRename} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridDefault">
                                            <Form.Label></Form.Label>
                                            <Form.Check className="mt-3" type="switch" name='is_default' label={t("Is Default")} defaultChecked={currentRole.is_default} onChange={handleChangeDefault} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Group controlId="manageRoles">
                                                <Form.Check name="manage_roles" type="switch" label={t("Roles")} defaultChecked={currentRole.access.manage_roles} disabled={isDisabled(currentRole.name)} onChange={handleAccessSwitch} />
                                            </Form.Group>
                                            <Form.Group controlId="manageAllUsers">
                                                <Form.Check name="manage_all_users" type="switch" label={t("All Users")} defaultChecked={currentRole.access.manage_all_users} disabled={isDisabled(currentRole.name)} onChange={handleAccessSwitch} />
                                            </Form.Group>
                                            <Form.Group controlId="manageChildUsers">
                                                <Form.Check name="manage_child_users" type="switch" label={t("Child Users")} defaultChecked={currentRole.access.manage_child_users} disabled={isDisabled(currentRole.name)} onChange={handleAccessSwitch} />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="manageAllCompanies">
                                                <Form.Check name="manage_all_companies" type="switch" label={t("All Companies")} defaultChecked={currentRole.access.manage_all_companies} disabled={isDisabled(currentRole.name)} onChange={handleAccessSwitch} />
                                            </Form.Group>
                                            <Form.Group controlId="manageSelfCompanies">
                                                <Form.Check name="manage_self_feedbacks" type="switch" label={t("Self Company")} defaultChecked={currentRole.access.manage_self_company} disabled={isDisabled(currentRole.name)} onChange={handleAccessSwitch} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Group controlId="managePlans">
                                                <Form.Check name="manage_plans" type="switch" label={t("Plans")} defaultChecked={currentRole.access.manage_roles} disabled={isDisabled(currentRole.name)} onChange={handleAccessSwitch} />
                                            </Form.Group>
                                            <Form.Group controlId="manageAllSubscriptions">
                                                <Form.Check name="manage_all_subscriptions" type="switch" label={t("All Subscriptions")} defaultChecked={currentRole.access.manage_all_users} disabled={isDisabled(currentRole.name)} onChange={handleAccessSwitch} />
                                            </Form.Group>
                                            <Form.Group controlId="manageSelfSubscriptions">
                                                <Form.Check name="manage_self_subscriptions" type="switch" label={t("Self Subscriptions")} defaultChecked={currentRole.access.manage_child_users} disabled={isDisabled(currentRole.name)} onChange={handleAccessSwitch} />
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                        </Col>
                                    </Row>
                                </Form>
                            }
                            {isLoading &&
                                <Spinner animation="border" role="status" size='sm'>
                                    <span className="visually-hidden">{t("Loading")}...</span>
                                </Spinner>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='madevo-btn-green' onClick={handleSave}>
                        {saving &&
                            <Spinner animation="border" role="status" size='sm'>
                                <span className="visually-hidden">{t("Saving")}...</span>
                            </Spinner>
                        }
                        {!saving &&
                            <span>{t("Save")}</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RoleEdit;