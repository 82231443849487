import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import AuthContext from '../../helpers/AuthContext';
import React, { useEffect, useContext, useState } from "react";


import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';

const ENDPOINT = process.env.REACT_APP_API_URL + "secure/company";

function CompanyEdit(props) {
    const { checkLoggedIn } = useContext(AuthContext);

    const [saving, setSaving] = useState(false)
    const [currentUser, setCurrentUser] = useState({ meta: {} });
    const [currentCompany, setCurrentCompany] = useState({ meta: {} });
    const [isNew, setIsNew] = useState(false);
    const [confirming, setConfirming] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation();

    function loadRolesData() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/roles";
        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                return res.data.roles;
            }
            return [];
        });
    }

    function loadCompanyData() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/company";
        return axios.get(LIST_ENDPOINT, {
            params: { id: props.company_id, is_active: props.active },
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setCurrentCompany(res.data.company);
                return res.data.company;
            }
            return {};
        })
            .catch((e) => {
                console.error(e.response.data.message);
            });
    }

    function handleCancel() {
        setConfirming(false);
        setSaving(false);
    }
    function handleOk() {
        setConfirming(false);
        setErrorMessage("");

        saveCompany()
            .then((res) => {
                setSaving(false);
                handleClose();
                checkLoggedIn().then(() => {
                    props.reLoad();
                })
            })
            .catch((e) => {
                if (e.response.data.error) {
                    setErrorMessage("Error: " + e.response.data.message);
                }
            })

    }

    function handleClose() {
        props.handleShow(false);
        setTimeout(() => {
            props.setCompanyId("");
        }, 500);
    }
    function handleSave() {
        for (const el of document.getElementById('edit-form').querySelectorAll("[required]")) {
            if (!el.reportValidity()) {
                return;
            }
        }
        setSaving(false);
        setConfirming(true);
    }

    function newCompany(l_roles) {
        return {
            name: "",
            is_active: false,
            meta: {
                activity: "",
                phone: "",
                address: "",
                address2: "",
                vat: "",
                city: "",
                zip: "",
            },
        }
    }

    function handleChangeField(e) {
        let field = e.target.name;
        let subfield = undefined;
        const val = e.target.value;

        if (field.includes('meta')) {
            subfield = field.replace("meta.", "");
            field = 'meta';
        }

        if (subfield === undefined) {
            currentCompany[field] = val;
        } else {
            currentCompany[field][subfield] = val;
        }
    }

    function handleChangeSwitch(e) {
        let field = e.target.name;
        let subfield = undefined;
        const val = e.target.checked;

        if (field.includes('meta')) {
            subfield = field.replace("meta.", "");
            field = 'meta';
        }

        if (subfield === undefined) {
            currentUser[field] = val;
        } else {
            currentUser[field][subfield] = val;
        }
    }

    function saveCompany() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/company";
        if (isNew) {
            return axios.post(ENDPOINT, currentCompany, {
                withCredentials: true,
            });
        }
        return axios.put(ENDPOINT, currentCompany, {
            withCredentials: true,
        });
    }

    useEffect(() => {
        setIsLoading(true);
        checkLoggedIn().then((res) => {
            if (res) {
                const my_uid = res;
                if (props.company_id !== undefined && props.company_id.length > 0) {
                    setIsNew(false);
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: props.user_id },
                        withCredentials: true,
                    })
                        .then((res) => {
                            let user = res.data.user;
                            setCurrentUser(user);
                            axios.get(ENDPOINT, {
                                params: { id: my_uid },
                                withCredentials: true,
                            })
                                .then((l_res) => {
                                    const canManageUsers = l_res.data.user.role.access.manage_child_users;
                                    if (canManageUsers) {
                                        loadRolesData();
                                        loadCompanyData();
                                    }
                                })
                        })
                        .catch((err) => {
                            if (err.response) {
                                setCurrentUser(null)
                            }
                        })
                } else {
                    setIsNew(true);
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: my_uid },
                        withCredentials: true,
                    })
                        .then((l_res) => {
                            const canManageUsers = l_res.data.user.role.access.manage_child_users;
                            if (canManageUsers) {
                                loadRolesData()
                                    .then((res) => {
                                        setCurrentUser(newCompany(res));
                                        setIsNew(true);
                                    });
                            } else {
                                setCurrentUser(newCompany());
                            }
                        })
                }
                setIsLoading(false);
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts


    return (
        <>
            <Modal
                show={confirming}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Are you sure?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isNew &&
                        <p>{t("confirm.new.company")}</p>
                    }
                    {!isNew &&
                        <p>{t("confirm.modify.company")}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="success" onClick={handleOk}>
                        {t("Yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    {!isLoading && isNew &&
                        <Modal.Title>{t("New Company")}</Modal.Title>
                    }
                    {!isLoading && !isNew &&
                        <Modal.Title>{t("Edit Company")}</Modal.Title>
                    }
                    {isLoading &&
                        <Modal.Title>{t("Loading")}...</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        errorMessage.length > 0 &&
                        <Row>
                            <Col className="d-flex justify-content-between mb-4">
                                <Badge bg="danger">{errorMessage}</Badge>
                            </Col>
                        </Row>

                    }
                    {isLoading &&
                        <div className="text-center">
                            <Spinner animation="border" role="status" size='sm' variant='secondary'>
                                <span className="visually-hidden">{t("Loading")}...</span>
                            </Spinner>
                        </div>
                    }
                    {!isLoading && currentCompany &&
                        <Row>
                            <Col className="d-flex justify-content-start mb-4 col-8">
                            {currentCompany.created_at &&
                                <strong className='text-small'>{t("Created At")}: {currentCompany.created_at && !isNew ? moment(currentCompany.created_at).format('MMMM Do YYYY, H:mm') : ' - '}</strong>
                            }
                            </Col>
                            <Form.Group as={Col} className="d-flex justify-content-end pe-3 col-4">
                                <Form.Check className="switch-success" id="isActive" name="is_active" type="switch" label={t("Is Active")} defaultChecked={currentCompany.is_active} onChange={handleChangeSwitch} />
                            </Form.Group>
                        </Row>
                    }
                    <Row>
                        <Col>
                            {!isLoading && currentCompany &&
                                <Form
                                    id='edit-form'
                                    className="pb-2 mb-2"
                                >
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridTitle">
                                            <Form.Label>{t("Name")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="name" type="text" defaultValue={currentCompany.name} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridActivity">
                                            <Form.Label>{t("Activity")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="meta.activity" type="text" defaultValue={currentCompany.meta.activity} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>{t("Email")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="meta.email" type="email" defaultValue={currentCompany.meta.email} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridPhone">
                                            <Form.Label>{t("Phone")}</Form.Label>
                                            <Form.Control name="meta.phone" defaultValue={currentCompany.meta.phone} onChange={handleChangeField} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridAddress">
                                            <Form.Label>{("Address")}</Form.Label>
                                            <Form.Control name="meta.address" defaultValue={currentCompany.meta.address} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridAddress2">
                                            <Form.Label>{t("Address 2")}</Form.Label>
                                            <Form.Control name="meta.address2" defaultValue={currentCompany.meta.address2} onChange={handleChangeField} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridTitle">
                                            <Form.Label>{t("VAT")}</Form.Label>
                                            <Form.Control name="meta.vat" type="text" defaultValue={currentCompany.meta.vat} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label>{t("City")}</Form.Label>
                                            <Form.Control name="meta.city" defaultValue={currentCompany.meta.city} onChange={handleChangeField} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridZip">
                                            <Form.Label>{t("Zip")}</Form.Label>
                                            <Form.Control
                                                name="meta.zip"
                                                defaultValue={currentCompany.meta.zip} onChange={handleChangeField}
                                            />
                                        </Form.Group>
                                    </Row>
                                </Form>
                            }
                            {!currentCompany &&
                                <Spinner animation="border" role="status" size='sm'>
                                    <span className="visually-hidden">{t("Loading")}...</span>
                                </Spinner>
                            }
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='madevo-btn-green' onClick={handleSave}>
                        {saving &&
                            <Spinner animation="border" role="status" size='sm'>
                                <span className="visually-hidden">{t("Saving")}...</span>
                            </Spinner>
                        }
                        {!saving &&
                            <span>{t("Save")}</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CompanyEdit;


