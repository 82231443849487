import Nav from 'react-bootstrap/Nav';
import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import AuthContext from "../helpers/AuthContext";
import React, { useEffect, useContext } from "react";

import Navbar from "../navbar";
import Footer from "../footer";
import { Link, Outlet, useLocation } from 'react-router-dom';
import AuthContext from '../../helpers/AuthContext';

import { useTranslation } from 'react-i18next';

function Accounts() {

    const { userRole, checkLoggedIn } = useContext(AuthContext);
    const location = useLocation();
    const { t } = useTranslation();

    function isActive(name) {
        return location.pathname.includes(name) ? " active " : "";
    }

    useEffect(() => {
        if (userRole === undefined || userRole.access === undefined) {
            checkLoggedIn();
        }
    })

    return (
        <Container className="main-container">
            <Navbar></Navbar>
            <h3>{t("Account Management")}</h3>
                <Row>
                    <Col>
                    <div className='mt-4 p-4'>
                        <Row className=''>
                            <Col className="d-flex justify-content-between">
                                <Nav variant="tabs" className='container-fluid'>
                                    {userRole && userRole.access && (userRole.access.manage_all_users || userRole.access.manage_child_users) &&
                                        <Nav.Item>
                                            <Link to={"/accounts/list"} className={'nav-link' + isActive('list')}>{t("Accounts")}</Link>
                                        </Nav.Item>
                                    }
                                    {userRole && userRole.access && (userRole.access.manage_roles) &&
                                        <Nav.Item>
                                            <Link to={"/accounts/roles"} className={'nav-link' + isActive('roles')}>{t("Roles")}</Link>
                                        </Nav.Item>
                                    }
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Container className='tab-body-container container-fluid border-start border-bottom border-end rounded-bottom p-4'>
                                    <Outlet />
                                </Container>
                            </Col>
                        </Row>
                    </div>
                    </Col>
                </Row>
            <Footer />
        </Container>
    );
}

export default Accounts;