import axios from 'axios';
import Cookies from "js-cookie";
import { jwtDecode } from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL, // Backend URL
  withCredentials: true, // Send cookies (for refresh token)
});

const refreshApi = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Include cookies for refresh token
});

api.interceptors.request.use(
  (config) => {
    // USING COOKIES
    // const token = localStorage.getItem('madevo-app-token');
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const accessToken = localStorage.getItem('madevo-app-token');
        if (accessToken) {
            const { exp } = jwtDecode(accessToken);
            const isExpired = Date.now() / 1000 >= exp;
            if (isExpired) {
              const response = await refreshApi.post(API_URL + 'refresh-token', null, { withCredentials: true });
              if (response && response.data && !response.data.error) {
                const newAccessToken = response.data.token;
                if (newAccessToken) {
                  localStorage.setItem("madevo-app-token", newAccessToken);
                } else {
                  localStorage.removeItem('madevo-app-token');
                  return Promise.reject(error);
                }
              } else {
                localStorage.removeItem('madevo-app-token');
                return Promise.reject(response);
              }
            } else {
              return Promise.reject(error);  
            }
          } else {
            return Promise.reject(error);
          }
        // originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (err) {
        // window.location.href = '/login';
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default api;