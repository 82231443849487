import { Link } from "react-router-dom";
import AuthContext from "../helpers/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { GearFill } from 'react-bootstrap-icons';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SystemSettings from "./system_settings";
import SideBar from "./sidebar";

function TopNavbar() {
    const { isLoggedIn, logout, userRole } = useContext(AuthContext);
    const location = useLocation();
    const { t, i18n } = useTranslation();

    // System Settings
    const [showSystemSettings, setShowSystemSettings] = useState(false);

    function isActive(name) {
        return location.pathname.includes(name) ? " active " : "";
    }

    function setLanguage(lng) {
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng);
        moment.locale(lng);
    }

    const accountItems = [
        {
            path: '/accounts',
            title: t('Users'),
            only: ["admin", "manager"],
        },
        {
            path: '/companies',
            title: t('Companies'),
            only: ["admin"],
        },
        {
            path: '/plans',
            title: t('Plans'),
            only: ["admin"],
        },
        {
            path: '/subscriptions',
            title: t('Subscriptions'),
            only: ["admin", "manager"],
        },
    ];

    const subscriptionItems = [
        {
            path: '/plans',
            title: t('Plans'),
            only: ["admin"],
        },
        {
            path: '/subscriptions',
            title: t('Subscriptions'),
            only: ["admin", "manager"],
        },
    ];

    const loggedInItems = [
        {
            path: '/datasources',
            title: t('Datasources'),
            only: ["admin", "manager"],
        },
        {
            path: '/alerts',
            title: t('Alerts'),
            only: [],
        },
    ];

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'en';
        setLanguage(lang);
    }, []);

    return (
        <>
        <SideBar />
        <Navbar expand="xl" fixed="top" className="nav-header-shadow" data-bs-theme="light">
            <Container className="justify-content-end">
                <Navbar.Toggle aria-controls="top-nav-bar"  />
                <Navbar.Collapse id="top-nav-bar" className="justify-content-end">
                    {/* <Nav className="me-auto">
                        {isLoggedIn &&
                                    <NavDropdown title={t("Subscriptions")} id="basic-nav-dropdown">
                                        {
                                            subscriptionItems.map((acc) => {
                                                return <NavDropdown.Item as={Link} key={acc.path} to={acc.path}>{acc.title}</NavDropdown.Item>
                                            })
                                        }
                                    </NavDropdown>
                        }
                        {isLoggedIn &&
                            loggedInItems.filter((item) => item.only.length === 0 || item.only.includes(userRole.name)).map((item, i) => {
                                return (
                                    <Nav.Link as={Link} key={item.path} className={"nav-link" + isActive(item.path)} to={item.path}>{item.title}</Nav.Link>
                                )

                            })
                        }
                        <Nav.Link as={Link} className={"nav-link" + isActive("assistant")} to={"/assistant"}>
                            <Image className="mb-1" src="/assistant_icon.png" width={16} roundedCircle /> {t("Assistant")}
                        </Nav.Link>
                    </Nav> */}
                    <Nav className="mw-auto">
                        <ul className="d-flex navbar-nav me-0 mb-2 mb-lg-0">
                            {!isLoggedIn ?
                                <Nav.Link as={Link} className={"nav-link" + isActive('/login')} aria-current="page" to="/login">{t("Login")}</Nav.Link>
                                :
                                <>
                                    <NavDropdown title={i18n.language} id="basic-nav-dropdown">
                                        {
                                            Object.keys(i18n.options.resources).map((lng) => {
                                                return <NavDropdown.Item key={lng} href="#action/3.1" onClick={() => setLanguage(lng)}>{lng}</NavDropdown.Item>
                                            })
                                        }
                                    </NavDropdown>
                                    {isLoggedIn &&
                                    <NavDropdown title={t("Accounts")} id="basic-nav-dropdown" className={isActive('/accounts')+isActive('/companies')+isActive("/plans")+isActive("/subscriptions")}>
                                        {
                                            accountItems.map((acc) => {
                                                return <NavDropdown.Item as={Link} key={acc.path} to={acc.path} className={isActive(acc.path)}>{acc.title}</NavDropdown.Item>
                                            })
                                        }
                                    </NavDropdown>
                                    }
                                    <Nav.Link as={Link} className={"nav-link" + isActive('/profile')} aria-current="page" to="/profile">{t('Profile')}</Nav.Link>
                                    <Nav.Link className="nav-link" role="button" aria-disabled="true" onClick={logout}>{t('Logout')}</Nav.Link>
                                    <Nav.Link className="nav-link madevo-secondary" role="button" aria-disabled="true" onClick={() => setShowSystemSettings(true)}><GearFill/></Nav.Link>
                                </>
                            }
                        </ul>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        {showSystemSettings &&
            <SystemSettings show={showSystemSettings} handleShow={setShowSystemSettings} />
        }
        </>
    )
}

export default TopNavbar;
