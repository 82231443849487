import { useContext } from 'react';
import Image from 'react-bootstrap/Image';
import { Link, useLocation } from 'react-router-dom';
import AuthContext from '../helpers/AuthContext';
import { useTranslation } from 'react-i18next';

function SideBar() {
    const { isLoggedIn, userRole } = useContext(AuthContext);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const loggedInItems = [
        {
            path: '/datasources',
            title: t('Datasources'),
            icon: 'datasources.png',
            only: ["admin", "manager"],
            style: {
                width: "60%",
                paddingTop: "2px",
            }
        },
        {
            path: '/alerts',
            title: t('Alerts'),
            icon: 'alerts.png',
            only: [],
            style: {
                width: "65%",
                paddingTop: "2px",
            }
        },
        {
            path: '/assistant',
            title: t("Madevo") + " " + t('AI') + " " + t('Assistant'),
            icon: 'assistant.png',
            only: [],
            style: {
                width: "100%",
                paddingTop: "2px",
            }
        },
    ];
    function isActive(name) {
        return location.pathname.includes(name) ? " active " : "";
    }
    return (
        <nav id="sidebar" className='p-4'>
            <div className="text-center mb-4">
                <Link to={"/"}>
                    <Image src="/logo.png" width={110} />
                </Link>
            </div>
            <div className='text-center side-buttons'>
            {isLoggedIn &&
                loggedInItems.filter((item) => item.only.length === 0 || item.only.includes(userRole.name)).map((item, i) => {
                    return (
                        <Link as={Link} key={item.path} className={"nav-link " + isActive(item.path)} to={item.path}>
                            <div className="side-button justify-content-center mx-auto">
                                <Image src={'/icons/'+item.icon} style={item.style} />
                            </div>
                            <div className='mb-4 button-title mt-2'>
                                {item.title}
                            </div>
                        </Link>
                    )

                })
            }
            </div>
        </nav>
    )
}

export default SideBar;