import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import AuthContext from '../../helpers/AuthContext';
import React, { useEffect, useContext, useState } from "react";


import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { ArrowClockwise, Play, PlusSquare, X } from 'react-bootstrap-icons';

const ENDPOINT = process.env.REACT_APP_API_URL + "secure/alert";

function AlertEdit() {
    const { id } = useParams();
    const navigate = useNavigate();

    const { userId, checkLoggedIn } = useContext(AuthContext);
    const [activeAlert, setActiveAlert, runAlert] = useOutletContext();

    const [saving, setSaving] = useState(false)
    const [currentUser, setCurrentUser] = useState({ meta: {} });
    const [currentAlert, setCurrentAlert] = useState({});
    const [isNew, setIsNew] = useState(false);
    const [confirming, setConfirming] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [datasources, setDatasources] = useState([]);
    const [assets, setAssets] = useState([]);
    const [values, setValues] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();

    const comp_map = {
        "gt": ">",
        "eq": "=",
        "lt": "<",
    }

    const timeframe = [
        {
            key: 1,
            val: t("Last Day"),
        },
        {
            key: 7,
            val: t("Last Week"),
        },
        {
            key: 30,
            val: t("Last Month"),
        },
        {
            key: 90,
            val: t("Last 90 Days"),
        },
    ]

    const timeframe_from = [
        {
            key: 'now',
            val: t("Now"),
        },
        {
            key: 'data',
            val: t("Last Data Point"),
        },
    ]

    const based_on = [
        {
            key: 'real',
            val: t("Real Data"),
        },
        {
            key: 'forecasted',
            val: t("Forecasted Data"),
        },
    ]

    function toggleSendEmail() {
        currentAlert.meta['send_email'] = !currentAlert.meta['send_email']
        setCurrentAlert(Object.assign({}, currentAlert));
    }

    function loadRolesData() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/roles";
        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                return res.data.roles;
            }
            return [];
        });
    }

    function loadListDatasources() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasources";
        setIsLoading(true);

        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setDatasources(res.data.datasources);
            }
            setIsLoading(false);
        });
    }

    function loadAlertData() {
        setIsLoading(true);
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/alert";
        return axios.get(LIST_ENDPOINT, {
            params: { id: id },
            withCredentials: true,
        }).then((res) => {
            setIsLoading(false);
            if (res && res.data) {
                setCurrentAlert(Object.assign({}, res.data.alert));

                return res.data.alert;
            }
            return {};
        })
            .catch((e) => {
                console.error(e.response.data.message);
            });
    }

    function handleCancel() {
        setConfirming(false);
        setSaving(false);
    }
    function handleOk() {
        setConfirming(false);
        setErrorMessage("");

        saveAlert()
            .then((res) => {
                setSaving(false);
                navigate("/alerts/edit/" + res.data.id)
            })
            .catch((e) => {
                console.error(e)
                if (e.response.data.error) {
                    setErrorMessage("Error: " + e.response.data.message);
                }
            })

    }

    function handleClose() {
        setConfirming(false);
    }
    function handleSave() {
        for (const el of document.getElementById('edit-form').querySelectorAll("[required]")) {
            if (!el.reportValidity()) {
                return;
            }
        }
        setSaving(false);
        setConfirming(true);
    }

    function newAlert() {
        return {
            title: "",
            is_active: false,
            datasource_id: "",
            user_id: userId,
            description: "",

            meta: {
                timeframe: 7,
                from: 'data',
                based_on: 'real',
                send_email: false,
            },

            criteria: [],
            check_history: [],

            status: 'ready',
        }
    }

    function newCriterion() {
        return {
            idx: currentAlert.criteria.length + 1,
            asset: "",
            value: "",
            comparison: "gt",
            constant: 10.0,
            is_failure: true,
        }
    }

    function handleChangeDatasource(dsid, cleanup, l_alert) {
        if (!dsid || dsid.length === 0) return;
        let local_alert = Object.assign({}, currentAlert);
        if (l_alert !== undefined) local_alert = l_alert;
        local_alert.datasource_id = dsid;
        if (cleanup) local_alert.criteria = [];
        setCurrentAlert(Object.assign({}, local_alert));
        setIsLoading(true);
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/datainfo";
        axios.post(ENDPOINT, { datasource_id: dsid }, {
            withCredentials: true,
        }).then((res) => {
            if (!res.data.error) {
                setAssets(res.data.data.assets);
                setValues(res.data.data.values);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function handleChangeField(e) {
        let field = e.target.name;
        let subfield = undefined;
        const val = e.target.value;

        if (field.includes('meta')) {
            subfield = field.replace("meta.", "");
            field = 'meta';
        }

        if (subfield === undefined) {
            currentAlert[field] = val;
        } else {
            currentAlert[field][subfield] = val;
        }
        setCurrentAlert(Object.assign({}, currentAlert));
    }

    function handleChangeActiveSwitch(e) {
        const val = e.target.checked;
        currentAlert.is_active = val;
        setCurrentAlert(Object.assign({}, currentAlert));
    }

    function saveAlert() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/alert";
        currentAlert.criteria.forEach((c) => {
            c.constant = parseFloat(c.constant);
        })
        if (isNew) {
            return axios.post(ENDPOINT, currentAlert, {
                withCredentials: true,
            });
        }
        return axios.put(ENDPOINT, currentAlert, {
            withCredentials: true,
        });
    }

    function removeCriterion(idx) {
        currentAlert.criteria.splice(idx - 1, 1)
        // reindex
        currentAlert.criteria.forEach((c, idx) => {
            c.idx = idx + 1;
        })
        setCurrentAlert(Object.assign({}, currentAlert))
    }

    function updateCriterion(idx, e) {
        let val = e.target.value;
        if (e.target.type === 'checkbox') {
            val = e.target.checked;
        }
        currentAlert.criteria[idx - 1][e.target.name] = val;
        setCurrentAlert(Object.assign({}, currentAlert))
    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (res) {
                const my_uid = res;
                if (id !== undefined && id.length > 0) {
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: userId },
                        withCredentials: true,
                    })
                        .then((res) => {
                            let user = res.data.user;
                            setCurrentUser(user);
                            loadRolesData();
                            loadListDatasources().then(() => {
                                loadAlertData().then((a) => {
                                    if (a !== undefined) handleChangeDatasource(a.datasource_id, false, a);
                                });
                            });
                            setIsNew(false);
                        })
                        .catch((err) => {
                            if (err.response) {
                                setCurrentUser(null)
                            }
                        })
                } else {
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: my_uid },
                        withCredentials: true,
                    })
                        .then((l_res) => {
                            const canManageUsers = l_res.data.user.role.access.manage_child_users;
                            if (canManageUsers) {
                                loadRolesData()
                                    .then((res) => {
                                        setCurrentAlert(newAlert(res));
                                        setIsNew(true);
                                    });
                            } else {
                                setCurrentAlert(newAlert());
                                setIsNew(true);
                            }
                            loadListDatasources();
                        })
                }
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts


    return (
        <>
            <Modal
                show={confirming}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Are you sure?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isNew &&
                        <p>{t("confirm.new.alert")}</p>
                    }
                    {!isNew &&
                        <p>{t("confirm.modify.alert")}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="success" onClick={handleOk}>
                        {t("Yes")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="row">
                <div className="col d-flex flex-column justify-content-start">
                    <h4>
                        {!isLoading && isNew &&
                            <Modal.Title>{t("New Alert")}</Modal.Title>
                        }
                        {!isLoading && !isNew &&
                            <Modal.Title>{t("Edit Alert")}</Modal.Title>
                        }
                        {isLoading &&
                            <Modal.Title>
                                {t("Loading")}...
                                <Spinner animation="border" role="status" size='sm' variant='secondary' className='mx-2'>
                                    <span className="visually-hidden">{t("Loading")}...</span>
                                </Spinner>
                            </Modal.Title>
                        }
                    </h4>
                    {!isLoading && !isNew &&
                        <strong >{t("Created At")}: {currentAlert.created_at ? moment(currentAlert.created_at).format('MMMM Do YYYY, H:mm') : ' - '}</strong>
                    }
                </div>
                <div className="col d-flex justify-content-center">
                    {!isLoading && currentAlert && !isNew &&
                            <Form.Group className="d-flex justify-content-center">
                                <Form.Check className="switch-success" id="isActive" name="is_active" type="switch" label={t("Is Active")} defaultChecked={currentAlert.is_active} onChange={handleChangeActiveSwitch} />
                            </Form.Group>
                    }
                </div>
                <div className="col d-flex justify-content-end">
                    <div>
                    {!isNew && !isLoading &&
                        <Button variant='outline-secondary' className='btn-sm mx-2' onClick={loadAlertData}>
                            <ArrowClockwise />
                        </Button>
                    }
                    {!isNew && currentAlert.status === 'running' &&
                        <Button
                            variant='outline-secondary'
                            className='madevo-btn-green brn-sm'
                            type='button'
                            onClick={() => {
                            }}
                            disabled
                        >
                            <Play /> {t("Running")}...
                        </Button>
                    }
                    {!isNew && currentAlert.status !== 'running' &&
                        <Button
                            variant='outline-secondary'
                            className='madevo-btn-green btn-sm'
                            onClick={() => {
                                runAlert(id)
                                currentAlert.status = "running";
                                setCurrentAlert(Object.assign({}, currentAlert))
                            }}
                            disabled={isLoading}
                        >
                            <Play /> {t("Run")}
                        </Button>
                    }
                    </div>
                </div>

            </div>
            {
                errorMessage.length > 0 &&
                <Row>
                    <Col className="d-flex justify-content-between mb-4">
                        <Badge bg="danger">{errorMessage}</Badge>
                    </Col>
                </Row>

            }
            <Row>
                <Col>
                    {currentAlert && !isLoading &&
                        <Form
                            id='edit-form'
                            className="pb-2 mb-2"
                        >
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridTitle">
                                    <Form.Label>{t("Title")} <span className="text-danger">*</span></Form.Label>
                                    <Form.Control required name="title" type="text" value={currentAlert.title} onChange={handleChangeField} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formDatasource">
                                    <Form.Label>{t("Datasource")} <span className="text-danger">*</span></Form.Label>
                                    <Form.Select name="datasource_id" value={currentAlert.datasource_id} onChange={(e) => handleChangeDatasource(e.target.value, true)} >
                                        <option value={""}>{t("None")}</option>
                                        {
                                            datasources.map((ds) => {
                                                return (<option value={ds._id} key={ds._id} >
                                                    {ds.name}
                                                </option>);
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Row>

                            {currentAlert.meta &&
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridTimeFrame" className='col-12 col-md-3'>
                                        <Form.Label>{t("Time Frame")}</Form.Label>
                                        <Form.Select name="meta.timeframe" value={currentAlert.meta['timeframe']} onChange={handleChangeField} >
                                            {
                                                timeframe.map((tf) => {
                                                    return (<option value={tf.key} key={tf.key} >
                                                        {tf.val}
                                                    </option>);
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridTimeFrameFrom" className='col-12 col-md-3'>
                                        <Form.Label>{t("From")}</Form.Label>
                                        <Form.Select name="meta.from" value={currentAlert.meta['from']} onChange={handleChangeField} >
                                            {
                                                timeframe_from.map((tf) => {
                                                    return (<option value={tf.key} key={tf.key} >
                                                        {tf.val}
                                                    </option>);
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridBasedOn" className='col-12 col-md-3'>
                                        <Form.Label>{t("Based On")}</Form.Label>
                                        <Form.Select name="meta.based_on" value={currentAlert.meta['based_on']} onChange={handleChangeField} >
                                            {
                                                based_on.map((bo) => {
                                                    return (<option value={bo.key} key={bo.key} >
                                                        {bo.val}
                                                    </option>);
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridSendEmail" className='col-12 col-md-3'>
                                        <Form.Label>{t("Send Email")}</Form.Label>
                                        <Form.Check className="switch-primary" name="meta.send_email" type="switch" defaultChecked={currentAlert.meta['send_email']} onChange={toggleSendEmail} />
                                    </Form.Group>
                                </Row>
                            }

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridDescription">
                                    <Form.Label>{t("Description")}</Form.Label>
                                    <Form.Control as="textarea" name="description" type="text" value={currentAlert.description} onChange={handleChangeField} />
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>{t("Criteria")}</Form.Label>
                                    <Form.Control as="div" >
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                variant='outline-secondary'
                                                className='madevo-btn-green btn-sm new-btn'
                                                type='button'
                                                onClick={() => {
                                                    currentAlert.criteria.push(newCriterion());
                                                    setCurrentAlert(Object.assign({}, currentAlert))
                                                }}
                                            >
                                                <PlusSquare className='new-btn-plus' /> {t("New Criterion")}
                                            </Button>
                                        </div>
                                        <div className="d-flex justify-content-center mt-4">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th className='text-center'>#</th>
                                                        <th className='text-center'>{t("Asset")}</th>
                                                        <th className='text-center'>{t("Value")}</th>
                                                        <th className='text-center'>{t("Comparison")}</th>
                                                        <th className='text-center'>{t("Constant")}</th>
                                                        <th className='text-center'>{t("Is Failure")}</th>
                                                        <th className='text-center'>{t("Delete")}</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {!isLoading && currentAlert.criteria &&
                                                        currentAlert.criteria.map((crit) => {
                                                            return <tr key={crit.idx}>
                                                                <td className='text-center'>{crit.idx}</td>
                                                                <td>

                                                                    <Form.Select name='asset' value={crit.asset} onChange={(e) => { updateCriterion(crit.idx, e) }} size='sm'>
                                                                        <option value={""}>{t("None")}</option>
                                                                        {
                                                                            assets.map((asset) => {
                                                                                return (<option value={asset} key={asset} >
                                                                                    {asset}
                                                                                </option>);
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                </td>
                                                                <td>

                                                                    <Form.Select name='value' value={crit.value} onChange={(e) => { updateCriterion(crit.idx, e) }} size='sm'>
                                                                        <option value={""}>{t("None")}</option>
                                                                        {
                                                                            values.map((v) => {
                                                                                return (<option value={v} key={v} >
                                                                                    {v}
                                                                                </option>);
                                                                            })
                                                                        }
                                                                    </Form.Select>

                                                                </td>
                                                                <td>

                                                                    <Form.Select name='comparison' value={crit.comparison} onChange={(e) => { updateCriterion(crit.idx, e) }} size='sm'>
                                                                        <option value={"lt"} className='text-center'>&lt;</option>
                                                                        <option value={"eq"} className='text-center'>=</option>
                                                                        <option value={"gt"} className='text-center'>&gt;</option>
                                                                    </Form.Select>

                                                                </td>
                                                                <td>

                                                                    <Form.Control size='sm' required name="constant" type="number" defaultValue={crit.constant} onChange={(e) => { updateCriterion(crit.idx, e) }} />

                                                                </td>
                                                                <td className='text-center'>

                                                                    <Form.Check className="switch-danger" id="isFailure" name="is_failure" type="switch" defaultChecked={crit.is_failure} onChange={(e) => { updateCriterion(crit.idx, e) }} />

                                                                </td>

                                                                <td className='text-center'>
                                                                    <span role='button'
                                                                        className="text-danger text-sm"
                                                                        onClick={() => removeCriterion(crit.idx)}
                                                                    >
                                                                        <X />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                    </Form.Control>
                                </Form.Group>
                            </Row>

                            {!isNew &&
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>{t("History")}</Form.Label>
                                        <Form.Control as="div" style={{ overflowY: 'scroll', maxHeight: '300px' }} >
                                            {!currentAlert || !currentAlert.check_history || currentAlert.check_history.length == 0 &&
                                                <p className="w-100 text-secondary text-center my-2">No Checks Yet</p>
                                            }
                                            {currentAlert && currentAlert.check_history && currentAlert.check_history.length > 0 &&
                                                currentAlert.check_history.map((run, ridx) => {
                                                    return <table className='table table-striped' key={ridx}>
                                                        <thead>
                                                            <tr>
                                                                <th>{t("When")}</th>
                                                                <th>{t("Check")}</th>
                                                                <th>{t("Result")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                run.map((incident, idx) => {
                                                                    return <tr key={incident.when + idx}>
                                                                        <td>
                                                                            {moment(incident.when).format('MMMM Do YYYY, H:mm')}
                                                                        </td>
                                                                        <td>
                                                                            <small>
                                                                                {incident.criterion.asset} &rarr; {incident.criterion.value} {comp_map[incident.criterion.comparison]} {incident.criterion.constant}
                                                                                {incident.criterion.is_failure &&
                                                                                    <Badge bg="secondary" className="mx-1">
                                                                                        {t("Is Failure")}
                                                                                    </Badge>
                                                                                }
                                                                                {!incident.criterion.is_failure &&
                                                                                    <Badge bg="secondary" className="mx-1">
                                                                                        {t("Is Not Failure")}
                                                                                    </Badge>
                                                                                }
                                                                            </small>
                                                                        </td>
                                                                        <td>
                                                                            {incident.status === 'failed' &&
                                                                                <span className='text-danger'>{t("failed")}</span>
                                                                            }
                                                                            {incident.status !== 'failed' &&
                                                                                <span className='text-success'>{t("passed")}</span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                            }


                            <Row className='d-flex justify-content-between'>
                                <Form.Group as={Col}>
                                    <Button variant="outline-secondary" onClick={() => navigate("/alerts/")}>
                                        {t("Cancel")}
                                    </Button>
                                </Form.Group>
                                <Form.Group as={Col} className='d-flex justify-content-end'>
                                    <Button variant="outline-secondary" className='madevo-btn-green' onClick={handleSave}>
                                        {saving &&
                                            <Spinner animation="border" role="status" size='sm'>
                                                <span className="visually-hidden">{t("Saving")}...</span>
                                            </Spinner>
                                        }
                                        {!saving &&
                                            <span>{t("Save")}</span>
                                        }
                                    </Button>
                                </Form.Group>
                            </Row>

                        </Form>
                    }
                    {!currentAlert &&
                        <Spinner animation="border" role="status" size='sm'>
                            <span className="visually-hidden">{t("Loading")}...</span>
                        </Spinner>
                    }
                </Col>
            </Row>
        </>
    );
}

export default AlertEdit;



