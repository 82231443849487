/* eslint-disable react-hooks/exhaustive-deps */
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { Check, Eye, PencilSquare, PlusSquare, Trash3 } from 'react-bootstrap-icons';

import { useContext, useEffect, useState } from "react";
import AuthContext from "../../helpers/AuthContext";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import CompanyEdit from "./company_edit";
import Spinner from 'react-bootstrap/Spinner';

function CompanyList() {
    const { userId, checkLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const [activeCompany, setActiveCompany] = useOutletContext();
    const [companyId, setCompanyId] = useState("");
    const [showActive, setShowActive] = useState(true);

    const [isLoading, setIsLoading] = useState(true);
    const [companies, setCompanies] = useState([]);

    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showRestore, setShowRestore] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [toRestore, setToRestore] = useState("");

    const { t } = useTranslation();

    function handleNewButton(id) {
        setActiveCompany("new");
        setCompanyId("")
        setShowEdit(true);
    }

    function loadListData(active) {
        setIsLoading(true);
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/companies";

        if (active === undefined) active = showActive;

        return axios.get(LIST_ENDPOINT, {
            params: { active: active },
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setCompanies(res.data.companies);
            }
            setIsLoading(false);
        });
    }

    function handleEditButton(id) {
        setCompanyId(id)
        setShowEdit(true);
    }

    function handleDeleteButton(id) {
        setToDelete(id);
        setShowDelete(true);
    }
    function handleDeleteOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/company";
        axios.delete(ENDPOINT, {
            data: { id: toDelete },
            withCredentials: true,
        }).then((res) => {
            setToDelete("");
            setShowDelete(false)
            loadListData()
            if (activeCompany === toDelete) {
                setActiveCompany("");
            }
        });
    }
    function handleDeleteCancel() {
        setToDelete("");
        setShowDelete(false);
    }

    function handleRestoreButton(id) {
        setToRestore(id);
        setShowRestore(true);
    }
    function handleRestoreOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/company";
        axios.patch(ENDPOINT,
            { id: toRestore },
            { withCredentials: true, }
        ).then((res) => {
            setToRestore("");
            setShowRestore(false)
            loadListData();
        });
    }
    function handleRestoreCancel() {
        setToRestore("");
        setShowRestore(false);
    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (!res) {
                navigate("/login");
            }
            if (res) {
                const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                axios.get(INFO_ENDPOINT, {
                    params: { id: res },
                    withCredentials: true,
                }).then((res) => {
                    if (res && res.data) {
                        const canManageCompanies = res.data.user.role.access.manage_all_companies || res.data.user.role.access.manage_self_company;
                        if (!canManageCompanies) {
                            navigate("/");
                        } else {
                            loadListData();
                        }
                    } else {
                        navigate("/login");
                    }
                })
                    .catch((e) => {
                        console.error(e);
                    })
            } else {
                navigate("/");
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <>
            <div className='row my-2'>
                <div className="col d-flex justify-content-start">
                    <h4 className='madevo-text-secondary'>{t("Companies")}</h4>
                </div>
                <div className="col d-flex justify-content-center">
                    {isLoading &&
                        <Spinner animation="border" role="status" size='sm' variant='secondary'>
                            <span className="visually-hidden">{t("Loading")}...</span>
                        </Spinner>
                    }
                    {!isLoading &&
                        <Form.Group className="">
                            <Form.Check className="switch-success" id="isActive" name="is_active" type="switch" label={t("Active") + ' ' + t("Companies")} defaultChecked={showActive} onChange={() => { setShowActive(!showActive); loadListData(!showActive); }} />
                        </Form.Group>
                    }
                </div>
                <div className="col d-flex justify-content-end py-1">
                    <Button variant='outline-secondary' className='btn-sm madevo-btn-green new-btn' onClick={handleNewButton}>
                        <PlusSquare className='new-btn-plus' /> {t("New Company")}
                    </Button>
                </div>
            </div >
            {
                companies.filter((item) => item.is_active === showActive).map((item) => {
                    return (
                        <ListGroup className='my-2'>
                            <ListGroup.Item key={item._id}>
                                <div className='row'>
                                    <div className="col d-flex flex-column justify-content-start">
                                        <h5 className="mb-1">{item.name}</h5>
                                        <small className="text-body-secondary">
                                            {item.name.toLowerCase() !== "system" &&
                                                <Badge bg="primary" className="mx-1">
                                                    {item.name.toLowerCase() !== "system" ? item.meta.activity ?? "- activity not set -" : "System Configuration"}
                                                </Badge>
                                            }
                                            {item.name.toLowerCase() === "system" &&
                                                <Badge bg="danger" className="mx-1">
                                                    System Company cannot be editted
                                                </Badge>
                                            }
                                        </small>
                                    </div>
                                    <div className="col d-flex justify-content-center py-3">
                                        <small>
                                            <strong >
                                                {t("Created At")}: {moment(item.created_at).format('MMMM Do YYYY, H:mm')}
                                            </strong>
                                        </small>
                                    </div>
                                    <div className="col d-flex justify-content-end">
                                        {item.name.toLowerCase() !== "system" &&
                                            <div className='mt-3'>
                                                <PencilSquare role='button' className='list-action' onClick={() => handleEditButton(item._id)} title={t("Edit")} />
                                                {showActive &&
                                                    <Trash3 role='button' className='list-action red-action' onClick={() => handleDeleteButton(item._id)} title={t("Delete")} />
                                                }
                                                {!showActive &&
                                                    <Check role='button' className='list-action' onClick={() => handleRestoreButton(item._id)} title={t("Retsore")} />
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    )
                }
                )
            }
            {
                showEdit &&
                <CompanyEdit show={showEdit} handleShow={setShowEdit} user_id={userId} company_id={companyId} setCompanyId={setCompanyId} reLoad={loadListData} companies={companies} active={showActive} />
            }
            {
                showDelete &&
                <>
                    <Modal
                        show={showDelete}
                        onHide={() => setShowDelete(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.delete.company")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleDeleteCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleDeleteOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
            {
                showRestore &&
                <>
                    <Modal
                        show={showRestore}
                        onHide={() => setShowRestore(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you Sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.restore.company")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleRestoreCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleRestoreOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    );
}

export default CompanyList;
